import React, { useEffect } from "react";
import Layout from "../../layout";
import { Imagesdata } from "../../assets/images/imageData";
import Slider from "react-slick";
import { NavLink, Link } from "react-router-dom";

const Landing = () => {
  //this is to force the screen to start at the top of the page when loading a new page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    BannerImage,
    BtnIcon,
    ExperinceImage,
    BlackArrow,
    InvestorsImg,
    PrintingImg,
    ConsultingImg,
    DataImg,
    ExpertImg,
    BlogOne,
    BlogTwo,
    BlogThree,
  } = Imagesdata || {};

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Layout>
      <div className="home_banner">
        <figure>
          <img src={BannerImage} alt="" />
        </figure>
        <div className="container">
          <div className="banner__text">
            <h1>Delivering Results</h1>
            <p>
              Providing experienced professionals to the intelligence community.
              Delivering innovative results... fast!
            </p>
            <NavLink to="/contact" className="btn">
              Get in touch
              <img src={BtnIcon} alt="" />
            </NavLink>
          </div>
        </div>
        <div className="down-arrow">
          <a href="#nextSection">
            SCROLL DOWN
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </div>
      </div>

      {/* <!-- experince  --> */}

      <div className="experince" id="nextSection">
        <div className="row flex">
          <div className="img__box" data-aos="fade-right">
            <figure>
              <img src={ExperinceImage} alt="" />
            </figure>
          </div>
          <div className="text__box" data-aos="fade-left">
            <span className="line">About</span>
            <h2>Backed by years of combined experience</h2>
            <p>
              The BK Consulting team draws from their vast and diverse
              background in commercial and government software development and
              implementation. BK Consulting offers a wide range of capabilities
              for any project need. Our Team is continually learning new
              applications of existing technologies along with cutting edge
              ideas to solve problems.
            </p>
            <ul className="flex align-center half__line">
              <li>
                <Link to="about">
                  Overview <img src={BlackArrow} alt="" />
                </Link>
              </li>
              <li>
                <Link to="career">
                  Potential Employees <img src={BlackArrow} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* service Section */}

      <div className="service">
        <div className="container">
          <div className="s-text" data-aos="fade-up">
            <span className="line">Services</span>
            <h2>What We Bring To The Table</h2>
            <p>
              The BK Consulting team brings agility, efficiency, and continuous 
              improvement to their software development process drawn from their vast and diverse
              background in commercial and government software development and
              implementation.{" "}
            </p>
          </div>
          <div className="row">

          <div className="col" data-aos="fade-up">
              <div className="icon">
                <figure>
                  <img src={PrintingImg} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>DevOps</h3>
                <p>
                  DevOps embodies a collaborative culture and set of practices that bridge 
                  the gap between development and operations teams, promoting seamless integration, 
                  automation, and continuous delivery. At BK Consulting, we fostering effective communication, streamlined 
                  processes, and efficient tooling, enabling rapid software development, 
                  deployment, and monitoring, resulting in increased agility, improved reliability, 
                  and accelerated time-to-market for the organization.
                </p>
                <ul className="flex align-center half__line">
                  <li>
                    <Link to="service" state={{ linkPosition: "devOpsSection"}}>
                      Read More
                      <img src={BlackArrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="icon">
                <figure>
                  <img src={PrintingImg} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Software Engineering and Architecture</h3>
                <p>
                  Software engineering and architecture are essential for any company that 
                  seeks to develop high-quality, scalable, and maintainable software products. 
                  At BK Consulting, we have a team of experienced software engineers who work 
                  in close collaboration with our skilled architects to design and implement 
                  robust and efficient software systems. 
                </p>
                <ul className="flex align-center half__line">
                  <li>
                    <Link to="service" state={{ linkPosition: "softwareEngineeringSection"}}>
                      Read More
                      <img src={BlackArrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="icon">
                <figure>
                  <img src={ConsultingImg} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Systems Engineering</h3>
                <p>
                  Systems engineering is a critical discipline that allows companies 
                  to develop complex systems, products, and processes.  At BK Consulting, we provide
                  a comprehensive approach, allowing companies achieve greater efficiency, 
                  reliability, and performance across their operations.
                </p>
                <ul className="flex align-center half__line">
                  <li>
                    <Link to="service" state={{linkPosition: "systemsEngineeringSection"}}>
                      Read More
                      <img src={BlackArrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="icon">
                <figure>
                  <img src={DataImg} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Database Engineering</h3>
                <p>
                  Database engineering is a critical component of any modern company's digital strategy. 
                  At BK Consulting, we collaborate closely with software development teams to support application development, 
                  implement data models, and optimize database queries, enabling seamless data management and 
                  reliable information access for the organization.
                </p>
                <ul className="flex align-center half__line">
                  <li>
                    <Link to="service" state={{linkPosition: "databaseEngineeringSection"}}>
                      Read More
                      <img src={BlackArrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="icon">
                <figure>
                  <img src={DataImg} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Data Science</h3>
                <p>
                  At BK Consulting, we leverage advanced analytics, statistical modeling, and machine learning techniques to 
                  extract valuable insights from vast amounts of data. Our data science services empower businesses to 
                  make data-driven decisions, uncover hidden patterns, predict trends, optimize processes, and identify
                  new opportunities, enabling them to stay competitive, drive innovation, and achieve sustainable 
                  growth in today's data-driven world.
                </p>
                <ul className="flex align-center half__line">
                  <li>
                    <Link to="service" state={{linkPosition: "dataScienceSection"}}>
                      Read More
                      <img src={BlackArrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col get_touch" data-aos="fade-up">
              <h3>Get In Touch With us</h3>
              <NavLink to="/contact" className="btn">
                Contact us <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* Expert Section */}
      <div className="expert">
        <div className="trangle" data-aos="fade-right"></div>
        <div className="container">
          <div className="row flex space-between">
            <div className="col" data-aos="fade-right">
              <span className="line">Our Founder</span>
              <h2>Real world experience experts</h2>
              <p>
                Founder Michael Kottmann created BK Consulting with a mission to
                deliver creative and trusted professional services to the
                growing and ever changing technology industry. Incorporated in
                2011, BK Consulting strives for attracting world-class talent
                through continual workplace excellence.
              </p>
              <Link to="about" className="btn">
                Read More <img src={BtnIcon} alt="" />
              </Link>
            </div>
            <div className="col" data-aos="fade-left">
              <figure>
                <img src={ExpertImg} alt="" />
              </figure>
              <div className="detail__box">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/mike-kottmann-00a2764/" target="_blank" rel="noreferrer">
                      <i
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <h6>Mike Kottmann</h6>
                    <span>Founder</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Latest News Section */}
      <div className="latest__news">
        <div className="container">
          <span className="line" data-aos="fade-up">
            Latest News
          </span>
          <h2 data-aos="fade-up">Check our featured insights</h2>
          <div className="slider__wrapper" data-aos="fade-up">
            <Slider {...settings}>
              <div>
                <div className="news__img">
                  <figure>
                    <img src={BlogOne} alt="" />
                  </figure>
                  <div className="text">
                    <h4>
                      BK Consulting sponsors and participates in charity golf
                      tournament
                    </h4>
                    <span>Published on 10/08/2015</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="news__img">
                  <figure>
                    <img src={BlogTwo} alt="" />
                  </figure>
                  <div className="text">
                    <h4>BK Consulting's Family day at National's Park.</h4>
                    <span>Published on 8/30/2015</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="news__img">
                  <figure>
                    <img src={BlogThree} alt="" />
                  </figure>
                  <div className="text">
                    <h4>BK Consulting employees attend UberConf.</h4>
                    <span>Published on 7/2015</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Landing;
