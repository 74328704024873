import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";
import pages from "./pages";
import Redirect from "./Redirect";

const { Home, About, Career, Contact, Service } = pages;

const router = createBrowserRouter(
  createRoutesFromElements(
        <>  
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="career" element={<Career />} />
          <Route path="contact" element={<Contact />} />
          <Route path="service" element={<Service />} />
          <Route path="timesheet" element={<Redirect url={ "https://bkconsults-online.ghg.com/" } />} /> 
        </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
