import Home from "./home";
import About from "./about";
import Career from "./career";
import Contact from "./contact";
import Service from "./service";

// eslint-disable-next-line
export default {
  Home,
  About,
  Career,
  Contact,
  Service,
};
