import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Imagesdata } from "../../assets/images/imageData";
import BannerSection from "../../components/bannerSection";
import JobListing from "./jobListing";

const Career = () => {
  useEffect(() => {
    //this is to force the screen to start at the top of the page when loading a new page.
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    //fetch the current jobs from the JAZZ HR API and put the data in the current state.  Replace "SECRETAPIKEY" in the fetch url with the actual API key.
    let ignore = false;
    fetch("https://api.bkconsults.com/careers")
    .then(res => res.json())
    .then(data => {
      // console.log("data", data)
      if (!ignore && !data.error){
        SetFullJobsList(data);
        SetFilteredJobsList(data);
      }
  })
  .catch(error => console.log("Error:", error));

  return () => {
    ignore = true;
  }
  }, []);
  
  const { SearchIcon } = Imagesdata || {};
  //declare the state variables needed
  const [activeJob, SetActiveJob] = useState(-1);
  const [activePopup, SetActivePopup] = useState(false);
  const [fullJobsList, SetFullJobsList] = useState([]);
  const [filteredJobsList, SetFilteredJobsList] = useState([])

  //filtering function for filtering the current jobs for what the user wants to look for.
  const filter = (keyword, location) => {
    const filteredList = [];
    const checkKeyword = keyword ? true : false;
    const checkLocation = location ? true : false;
    const lowerCasedKeyword = keyword.toLowerCase();
    const lowerCasedLocation = location.toLowerCase();
    
    fullJobsList.forEach( job => {
      let include = true;
      if (checkKeyword) {
        if (job.title.toLowerCase().indexOf(lowerCasedKeyword) === -1) include = false;
      }

      if (checkLocation) {
        if (job.city.toLowerCase() !== lowerCasedLocation && job.state.toLowerCase() !== lowerCasedLocation) include = false;
      }

      if (include) filteredList.push(job)
    })

    SetFilteredJobsList(filteredList)
  }

  return (
    <Layout>
      <BannerSection heading={"Careers"} />
      <div className="services__bring carrers">
        <div className="text" data-aos="fade-up">
          <span className="line">JOB OPENINGS</span>
          <h2>Working For BK Consulting</h2>
          <p>
            Are you interested in taking the next step in your career? You
            should consider BK Consulting. We are continually seeking highly
            qualified information technology professionals to join our growing
            team. If you are interested in joining the BK Consulting team,
            please send your resume and cover letter to careers@bkconsults.com.
          </p>
          <span></span>
          <div className="benefits">
            <div className="text">
              <h5>Benefits</h5>
                <p>
                    BK Consulting is proud to offer highly competitive salaries
                    and comprehensive benefits packages.
                </p>
            </div>
            <div className="box_wrapper flex space-between">
              <div className="box">
                  <h5>Vacation & Holidays</h5>
                  <ul>
                  <li>Thirty-two days of personal leave per year</li>
                  <li>
                      Six Federal holidays per year
                  </li>
                  </ul>
              </div>
              <div className="box">
                  <h5>Medical</h5>
                  <ul>
                  <li>100% Company paid Health, Dental, and Vision</li>
                  <li>
                      100% Company paid Short Term and Long Term Disability
                  </li>
                  <li>100% Company paid Life Insurance</li>
                  </ul>
              </div>
              <div className="box">
                  <h5>Other Benefits</h5>
                  <ul>
                  <li>Employee Referral bonuses</li>
                  <li>Annual fitness allowance ($150)</li>
                  <li>
                      Annual contribution towards term life, will and/or trust
                      ($1200)
                  </li>
                  </ul>
              </div>
              <div className="box">
                  <h5>Technology Allowances</h5>
                  <ul>
                  <li>Monthly cell phone allowance ($75)</li>
                  <li>Monthly Internet Service Provider allowance ($60)</li>
                  <li>Bi-yearly computer allowance ($1750)</li>
                  </ul>
              </div>
              <div className="box">
                  <h5>401(k)</h5>
                  <ul>
                  <li>
                      Company Contributes 15% of salary to Safe Harbor 401(k)
                  </li>
                  </ul>
              </div>
              <div className="box">
                  <h5>Continuing Education</h5>
                  <ul>
                  <li>Annual training/education allowance (up to $5000)</li>
                  </ul>
              </div>
          </div>
          </div>
        </div>
        <div className="serch_filter" data-aos="fade-up">
          <form onSubmit={(event) => {
            event.preventDefault();
            filter(document.getElementById("keyword").value, document.getElementById("location").value)
            }}>
            <div className="from_inner flex">
              <div className="form_section">
                <div className="form-group">
                  <img src={SearchIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Keyword"
                    className="from-control"
                    id="keyword"
                  />
                </div>
                <div className="form-group">
                  <img src={SearchIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Location"
                    className="from-control"
                    id="location"
                  />
                </div>
              </div>
              <div className="serch-btn">
                <button className="btn" type="submit">Search</button>
              </div>
            </div>
            <ul className="flex align-center space-between">
              <button className="btn" id="clear" type="button" onClick={() => SetFilteredJobsList(fullJobsList)}>Clear Filter</button>
              <li>{filteredJobsList.length} positions found</li>
            </ul>
          </form>
        </div>

        <div className="faq_accordian">
          {filteredJobsList.map((job, index) => {
            return <JobListing 
            key={job.id}
            index={index} 
            activeJob={activeJob} 
            SetActiveJob={SetActiveJob}
            activePopup={activePopup}
            SetActivePopup={SetActivePopup}
            job={job} 
            />
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Career;
