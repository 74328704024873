import "./assets/sass/style.scss";
import React from "react";
import { Routes, Route } from "react-router-dom";
import pages from "./pages";
// import { LocalToastProvider } from 'react-local-toast';

const { Home, About, Career, Contact, Service } = pages;

function App() {
  return (
    // <LocalToastProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service" element={<Service />} />
          <Route path="timesheet" render={() => { window.location.href = "https://bkconsults-online.ghg.com/"} } /> 
        </Routes>
      </div>
    // </LocalToastProvider>
  );
}

export default App;
