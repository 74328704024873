import React, { useEffect } from "react";
import BannerSection from "../../components/bannerSection";
import Layout from "../../layout";
import { Imagesdata } from "../../assets/images/imageData";
import { NavLink, useLocation } from "react-router-dom";

const Service = () => {
  let { state } = useLocation();

  //this is to force the screen to start at the top of the page when loading a new page.
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    //scroll to specific spot which was directed from where you navigated to this page from.
    if(state !== null && state.linkPosition) {
      document.getElementById(state.linkPosition).scrollIntoView()
    }
  }, [state]);
  const { BtnIcon, ServiceImg, ServiceImgTwo, DataImgMain, DevOpsImg, DataScienceImg } = Imagesdata || {};
  return (
    <Layout>
      <BannerSection heading={"Services"} />

      <div className="services__bring">
        <div className="trangle" data-aos="fade-left"></div>
        <div className="container">
          <div className="text" data-aos="fade-up">
            <span className="line">Services</span>
            <h2>What We Bring To The Table</h2>
            <p>
              The BK Consulting team draws from their vast and diverse
              background in commercial and government software development and
              implementation.{" "}
            </p>
          </div>
          <div className="row flex">
            <div className="img-box" data-aos="fade-right">
              <figure>
                <img src={ServiceImg} alt="" />
              </figure>
            </div>
            <div className="text-box" data-aos="fade-up" id="softwareEngineeringSection">
              <h2>Software Engineering and Architecture</h2>
              <p>
                Our company offers exceptional software engineering and architecture services 
                that help businesses leverage the latest technological advancements to 
                create efficient and robust software solutions. Our team of experienced engineers and 
                architects have a deep understanding of software development life cycle and employ 
                industry-leading software development methodologies and architectural 
                patterns to design and develop scalable and reliable software systems. 
              </p>
              <p>  
                We take pride in our ability to deliver cutting-edge software solutions 
                that meet our clients' business needs and exceed their expectations. Whether 
                you need to design and develop a new software system from scratch, or revamp an 
                existing system, we have the expertise and the tools to help you achieve your goals.
              </p>
              
              <NavLink to="/contact" className="btn">
                Get In Touch <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="services__bring engineering" id="systemsEngineeringSection">
        <div className="cricle"></div>
        <div className="container">
          <div className="row flex">
            <div className="text-box" data-aos="fade-right">
              <h2>Systems Engineering</h2>
              <p>
                Systems engineering is a critical component of successful technology 
                development for any organization. Our team of experienced systems 
                engineers are experts in managing complex systems and processes to ensure 
                the successful delivery of your technology projects. 
              </p>
              <p>
                We work closely with your organization to understand your unique 
                requirements and design systems that meet your needs, while also 
                ensuring scalability, flexibility, and maintainability. With a focus 
                on risk management and continuous improvement, our systems 
                engineering approach ensures that your technology investments are 
                optimized for performance, reliability, and cost-effectiveness. 
                Trust us to help you build and maintain the systems that power your business.
              </p>
              <NavLink to="/contact" className="btn">
                Get In Touch <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
            <div className="img-box" data-aos="fade-left">
              <figure>
                <img src={ServiceImgTwo} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="experince data_architecture data_engineering" id="databaseEngineeringSection">
        <div className="row flex">
          <div className="img__box" data-aos="fade-right">
            <figure>
              <img src={DataImgMain} alt="" />
            </figure>
          </div>
          <div className="text__box" data-aos="fade-left">
            <div className="detail">
              <h2>Database Engineering</h2>
              <p>
                Our company offers top-notch database engineering services to empower 
                businesses with robust and efficient data management solutions. 
                With a team of skilled and experienced database engineers, we specialize in designing, 
                developing, and optimizing databases tailored to your unique requirements. 
              </p>
              <p>
                We leverage the latest database technologies and industry best practices to ensure 
                optimal performance, data integrity, and scalability. From database design and 
                implementation to migration and performance tuning, our experts provide end-to-end 
                solutions to maximize the value of your data. We understand the critical role databases 
                play in modern business operations, and our meticulous approach guarantees secure and 
                reliable data storage and retrieval. Trust us to deliver tailored database engineering 
                solutions that propel your organization forward.
              </p>
              <NavLink to="/contact" className="btn">
                Get In Touch <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="services__bring engineering" id="devOpsSection">
        <div className="cricle"></div>
        <div className="container">
          <div className="row flex">
            <div className="text-box" data-aos="fade-right">
              <h2>DevOps</h2>
              <p>
                BK Consulting specializes in delivering exceptional DevOps services to drive 
                efficiency and innovation in your organization. Our team of skilled DevOps engineers 
                brings together the principles of development and operations to streamline 
                your software delivery pipeline. 
              </p>
              <p>
                We implement robust automation, continuous integration, and continuous deployment 
                practices to ensure rapid and reliable software releases. With our expertise in 
                cloud platforms, containerization, and infrastructure as code, we help you achieve scalability, 
                flexibility, and cost savings. Partner with us to unlock the full potential of DevOps and 
                transform your software development lifecycle.
              </p>
              <NavLink to="/contact" className="btn">
                Get In Touch <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
            <div className="img-box" data-aos="fade-left">
              <figure>
                <img src={DevOpsImg} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="experince data_architecture" id="dataScienceSection">
        <div className="row flex">
          <div className="img__box" data-aos="fade-right">
            <figure>
              <img src={DataScienceImg} alt="" />
            </figure>
          </div>
          <div className="text__box" data-aos="fade-left">
            <div className="detail">
              <h2>Data Science</h2>
              <p>
                Our company offers offers cutting-edge data science services to help businesses extract 
                valuable insights and make data-driven decisions. With a team of experienced data scientists 
                and machine learning experts, we specialize in leveraging advanced analytics techniques and 
                state-of-the-art algorithms to uncover hidden patterns, predict future trends, and optimize business processes. 
              </p>
              <p>
                We combine domain expertise with a deep understanding of statistical modeling and machine 
                learning to develop predictive models and sophisticated data-driven solutions. 
                Whether you need assistance with data exploration, predictive modeling, or building 
                intelligent systems, our data science services can provide you with actionable insights to 
                drive innovation and gain a competitive edge. 
              </p>
              <NavLink to="/contact" className="btn">
                Get In Touch <img src={BtnIcon} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default Service;
