import { useState } from 'react';
import { Imagesdata } from "../../assets/images/imageData";
import ResumeComponent from "./resumeComponent";


const JobListing = (props) => {
  const { LocationIcon, BlueIcon, ClenderIcon } = Imagesdata || {};
  const [loading, setLoading] = useState(false);

  //function to remove inline styling from any html tags that are returned in a job description.
  const cleanHTMLTags = (descriptionString) => {
    const regex = /<\s*([a-z][a-z0-9]*)\s.*?>/ig;
    return descriptionString.replace(regex, "<$1>");
  }

  //submit handler for applying for a job.
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let fileName = '';

    const formData = new FormData(e.target);
    //create a promise to start a chain that can synchronize asynchronous calls like the readAsArrayBuffer method, and window.btoa method
    const promiseChain = new Promise((resolve, reject) => {
      //begin reading the file
      const reader = new FileReader();

      reader.readAsArrayBuffer(formData.get("file-s" + props.job.id))
      fileName = formData.get("file-s" + props.job.id).name;      
      reader.onload = (e) => {
        resolve(reader)
      }
    })

    //invoke the promise and begin chaining
    Promise.resolve(promiseChain)
    //turning the file into binary
    .then((reader) => {
      let binary = "";
      const bytes = new Uint8Array(reader.result);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++){
        binary += String.fromCharCode(bytes[i])
      }
      return binary;
    }).then((binary) => {
      //turn the binary into Base64 encoding
      return window.btoa(binary)
    }).then((contents) => {
      //create the body for the eventual POST request.  Replace "SECTRETAPIKEY" with the actual API key in the message body.
      const messageBody = {
        firstName: formData.get("firstName"),
        lastName: formData.get("lastName"),
        phone: formData.get("phone"),
        email: formData.get("email"),
        coverLetter: formData.get("message"),
        base64Resume: contents,
        theFileName: fileName,
        job: props.job.id,
        applyDate: new Date().toISOString().slice(0, 10)
      }
      return messageBody;
    }).then(async data => {
      // send the POST request to the API
      await fetch("https://api.bkconsults.com/careers", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      })
      .then((response) => {props.SetActivePopup(false);setLoading(false);})
      .catch(error => {console.log("Error:", error);setLoading(false);})
    })
    .catch(error => {console.log("Error:", error);setLoading(false);})
  }

  return (
    <>
        <div className="accrodian__box" data-aos="fade-up">
        <div
            className={
            props.activeJob === props.index ? "accordion-title open" : "accordion-title"
            }
        >
            <ul>
            <li>
                <h6>{props.job.title}</h6>
                <span>
                <img src={LocationIcon} alt="" /> {props.job.city}, {props.job.state} - {props.job.type}
                </span>
                <span>
                <img src={ClenderIcon} alt="" /> {props.job.original_open_date}
                </span>
            </li>
            <li>
                <div className="readmore" onClick={() => props.SetActiveJob(props.index)}>
                Read More <img src={BlueIcon} alt="" />
                </div>
            </li>
            </ul>
        </div>
        <div
            className="accordion-content"
            style={{ display: props.activeJob === props.index ? "block" : "none" }}
        >
            <button
            className="applybtn btn"
            onClick={() => props.SetActivePopup(true)}
            >
            Apply
            </button>
            <div className="box_wrapper flex space-between">
              <div className="jobDescription" dangerouslySetInnerHTML={{__html: cleanHTMLTags(props.job.description)}}></div>
            </div>
        </div>
        </div>
        <div className={props.activePopup ? "pop-up active" : "pop-up"}>
        <div className="pop__up__inner">
               <div className="cross_btn" onClick={() => props.SetActivePopup(false)}>
                 <span></span>
                 <span></span>
               </div>
               <h2>Apply</h2>
               <form id="apply" onSubmit={handleSubmit}>
                 <div className="form_inner">
                   <h6>{props.job.title}</h6>
                   <div className="form-group"></div>
                   <div className="form-group">
                     <input
                       autoFocus
                       type="text"
                       className="form-control"
                       name="firstName"
                       placeholder=" "
                       required
                     />
                     <label htmlFor="firstName">Your first name*</label>
                   </div>
                   <div className="form-group">
                     <input
                       type="text"
                       className="form-control"
                       name="lastName"
                       placeholder=" "
                       required
                     />
                     <label htmlFor="lastName">Your last name*</label>
                   </div>
                   <div className="form-group">
                     <input
                       type="number"
                       className="form-control"
                       name="phone"
                       placeholder=" "
                     />
                     <label htmlFor="phone">Your phone number</label>
                   </div>
                   <div className="form-group">
                     <input
                       type="email"
                       className="form-control"
                       name="email"
                       placeholder=" "
                       required
                     />
                     <label htmlFor="email">Your email address*</label>
                   </div>
                   <div className="form-group full">
                     <textarea
                       placeholder=" "
                       name="message"
                       className="form-control"
                     ></textarea>
	                     <label htmlFor="message">Your message</label>
                   </div>
                   <ResumeComponent activePopup={props.activePopup} job={props.job}/>
                 </div>
               <div className="text">
                 <h6>*These fields are required</h6>
                 <p>
                   By clicking on Apply you agree that your data will be used by
                   BK Consulting to better respond to you. To find out more about
                   the management of your personal data and to exercise your
                   rights, see our privacy policy .
                 </p>
               </div>
               <br></br>
               <br></br>
              <div className="form-group full check__box">
                <button className="btn" >{loading ? (<span className='loader' style={{marginTop: "7px", marginBottom: "6px"}}></span>) : 'Submit'}</button>
              </div>
              </form>
            </div>
          </div>
    </>
  );
};

export default JobListing;