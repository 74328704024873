import React, { useState } from "react";

const ResumeComponent = (props) => {
  const [fileName, SetFileName] = useState("No selected file");

  if (document.getElementById("file-s" + props.job.id)) {
    //create an event listener to see if a file has been loaded so it can be rendered.
  const resumeInput = document.getElementById("file-s" + props.job.id);

  resumeInput.addEventListener('change', () => {
    SetFileName(resumeInput.files[0].name);
  })

  } 
  
  return (
    <>
      <div className="form-group full upload-box">
        <label htmlFor="">RESUME*</label>
        <div className="file-upload">
          <input id={"file-s" + props.job.id} name={"file-s" + props.job.id} type="file" required accept=".doc,.docx,.pdf,.pages" />
          <label htmlFor={"file-s" + props.job.id}>
            <span>{fileName}</span>
            <button>Browse</button>
          </label>
        </div>
      </div>
    </>
  )
}

export default ResumeComponent;