import React, { useEffect, useRef, useState } from "react";
// import 'react-local-toast/dist/bundle.css';
import Layout from "../../layout";
import BannerSection from "../../components/bannerSection";
import ReCAPTCHA from "react-google-recaptcha";
// import { LocalToastTarget, useLocalToast } from 'react-local-toast';

const Contact = () => {
  useEffect(() => {
    //this is to force the screen to start at the top of the page when loading a new page.
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  //reference to the reCAPTCHA element
  const captchaRef = useRef(null);
  //hook to show/hide notification
  // const {showToast, removeToast} = useLocalToast();
  const [loading, setLoading] = useState(false);

  //event handler for submitting the contact form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //receive recaptcha token and reset the captcha ref
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    //turn the data from the form into a usuable object
    const formData = new FormData(e.target);

    //send the message to the contact API
    await fetch("https://api.bkconsults.com/contact-us", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        name: formData.get("fullName"),
        phone: formData.get("phoneNumber"),
        email: formData.get("emailAddress"),
        company: formData.get("companyName"),
        desc: formData.get("userMessage"),
        recaptcha: token,
      })
    })
    .then(res => {
      // showToast('submitBtn', 'Thank you for contacting us<br /> we\'ll be in touch soon');
      //clear all of the fields once finished
      e.target.reset();
      //display notification
      setLoading(false);
    })
    .catch(error => {console.log("Error:", error); setLoading(false);})
    
  }

  return (
    <Layout hideCarrerForm={true}>
      <BannerSection
        heading={"We Want To Hear From You"}
        content={
          "Questions? Contact us with the form below and we'll reach out  within two business days."
        }
        contectBanner={true}
      />
      <div className="contact__form" data-aos="fade-up">
        <div className="form__section">
          <form action="" method="post" onSubmit={handleSubmit}>
            <div className="form_inner">
              <div className="form-group">
                <label htmlFor="fullName">Full Name*</label>
                <input
                  type="text"
                  required
                  className="form-control"
                  placeholder="John Doe"
                  name="fullName"
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailAddress">Email Address*</label>
                <input
                  type="email"
                  required
                  className="form-control"
                  placeholder="Enter email address"
                  name="emailAddress"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                />
              </div>
              <div className="form-group">
                <label htmlFor="companyName">Company</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                  name="companyName"
                />
              </div>
              <div className="form-group full">
                <label htmlFor="userMessage">Message*</label>
                <textarea
                  className="form-control"
                  placeholder="How can we help you?"
                  name="userMessage"
                  required
                ></textarea>
              </div>
              <div className="form-group full check__box">
                <div className="check">
                  <ReCAPTCHA sitekey="6Lf9nJsUAAAAALgXxM-8-tjiLAIUDF_vNbiLoRDF" ref={captchaRef} />
                </div>
                {/* <LocalToastTarget name="submitBtn"> */}
                  <button className="btn">{loading ? (<span className='loader' style={{marginTop: "7px", marginBottom: "6px"}}>a</span>) : 'Submit'}</button>
                {/* </LocalToastTarget> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
